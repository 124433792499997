import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export const FORMATS_QUERY = gql`
    query allFormatsPlatforms {
        allFormatsPlatforms
    }
`;

export const useQueryFormatsPlatform = (defaultValue = [], overwrite) => {
    const { loading, error, data } = useQuery(FORMATS_QUERY);
    const result = useMemo(() => {
        if (data && data.allFormatsPlatforms) {
            const platformsResults = JSON.parse(data.allFormatsPlatforms);
            const platformsFormats = {};
            for (const platform in platformsResults) {
                if (platform === "google_ads_search") {
                    platformsFormats[platform] = {};
                    platformsFormats[platform].static = [
                        {
                            value: "search",
                            label: "search",
                        },
                    ];
                } else {
                    platformsFormats[platform] = {};
                    platformsFormats[platform].dynamic = platformsResults[platform]?.dynamic?.map((format) => ({
                        value: format,
                        label: format,
                    }));
                    platformsFormats[platform].static = platformsResults[platform]?.static?.map((format) => ({
                        value: format,
                        label: format,
                    }));
                }
            }
            return platformsFormats;
        }

        return [];
    }, [data]);

    return { loading, error, formats: result };
};
