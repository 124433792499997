import { FormGroup, Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownArrow } from "../../img/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../img/up-arrow.svg";

const StyledFormGroup = styled.div`
    position: relative;
    .hidden {
        display: none;
    }
    .button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;

const StyledContent = styled.div`
    display: ${({ hidden }) => (hidden ? "none" : "flex")};
    flex-direction: ${({ direction }) => direction || "row"};
    .hidden {
        display: none !important;
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const StyledArrow = styled.div`
    width: 16px;
    height: 16px;
    margin: 5px;
    vertical-align: middle;
`;

const StyledContentItem = styled.div`
    width: ${({ width }) => width || "100%"};
    display: flex;
    padding-right: 10px;
    flex-direction: column;
    .hidden {
        display: none !important;
    }
`;

StyledContentItem.propTypes = { children: PropTypes.node };
const MessageMultipleField = ({
    values,
    onChangeValue,
    name,
    changes = {},
    renderComponent,
    addElement,
    removeElement,
    label,
    errors = {},
    loading,
    disabled,
    onRevert,
    collapsed,
    useColumnLayout = false,
}) => {
    const subErrors = Object.keys(errors).reduce((acc, k) => {
        const subKey = `${name}.`;
        if (k.startsWith(subKey)) {
            acc[k.substring(subKey.length)] = errors[k];
        }
        return acc;
    }, {});
    const [visible, setVisible] = useState(false);
    return (
        <StyledFormGroup>
            <FormGroup
                label={label}
                labelInfo={
                    changes[name] && (
                        <Tag minimal intent={Intent.PRIMARY} onRemove={onRevert ? () => onRevert(name) : null}>
                            Has changes
                        </Tag>
                    )
                }
                helperText={errors[name]}
                intent={errors[name] ? Intent.DANGER : changes[name] ? Intent.PRIMARY : Intent.NONE}
            >
                <StyledContent hidden={collapsed && !visible} direction={useColumnLayout ? "column" : "row"}>
                    {values[name]?.map((entry, index) => {
                        return (
                            <StyledContentItem width={useColumnLayout ? "100%" : "auto"}>
                                {renderComponent({
                                    index: index,
                                    value: entry,
                                    setValue: onChangeValue
                                        ? (x) => {
                                              let newArray = [...values[name]];
                                              newArray[index] = x;
                                              onChangeValue(name, newArray);
                                          }
                                        : null,
                                    removeEntry: null,
                                    disabled: loading || disabled,
                                    errors: subErrors,
                                })}
                                {removeElement({
                                    index: index,
                                    value: entry,
                                    setValue: onChangeValue
                                        ? (x) => {
                                              let newArray = [...values[name]];
                                              newArray = values[name].filter((_, i) => i !== index);
                                              onChangeValue(name, newArray);
                                          }
                                        : null,
                                })}
                            </StyledContentItem>
                        );
                    })}
                    {addElement({
                        setValue: onChangeValue
                            ? (x) => {
                                  let newArray = [...values[name]];
                                  newArray[newArray.length] = x;
                                  onChangeValue(name, newArray);
                              }
                            : null,
                    })}
                </StyledContent>
            </FormGroup>
            <StyledArrow className="button" hidden={!collapsed || visible} onClick={() => setVisible(!visible)}>
                <DownArrow>SHOW</DownArrow>
            </StyledArrow>
            <StyledArrow className="button" hidden={!collapsed || !visible} onClick={() => setVisible(!visible)}>
                <UpArrow>HIDE</UpArrow>
            </StyledArrow>
        </StyledFormGroup>
    );
};

MessageMultipleField.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            map: PropTypes.func,
            filter: PropTypes.func,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    renderComponent: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
    onChangeValue: PropTypes.func,
    onRevert: PropTypes.func,
    changes: PropTypes.object,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    collapsed: PropTypes.bool,
    useColumnLayout: PropTypes.bool,
};

MessageMultipleField.defaultProps = {
    onChangeValue: null,
    onRevert: null,
    changes: {},
    errors: {},
    loading: false,
    disabled: false,
    collapsed: false,
    useColumnLayout: false,
};

export default MessageMultipleField;
